import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Contacts from "../components/contacts"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="お問い合わせ"
        description="株式会社ファーストのお問い合わせ"
      />
      <div className="container">
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "gray",
            margin: "10px 0px",
          }}
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </div>
        <h3 className="title is-4" style={{ color: "gray" }}>
          お問い合わせ
        </h3>
        <section className="section">
          <p>
            各種ご相談は、Web問い合わせか電話にて受け付けております。お気軽にお問い合わせください。
          </p>
          <Contacts />
        </section>
      </div>
    </Layout>
  )
}

export default Contact
