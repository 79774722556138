import React, { useState } from "react"
import ContactForm from "../components/contact-form"
import ContactTel from "../components/contact-tel"

const Contacts = ({ contactFormType }) => {
  const [contactType, setContactType] = useState("form")
  return (
    <div className="mt-5">
      <div className="tabs is-boxed is-fullwidth">
        <ul>
          <li
            className={contactType === "form" ? "is-active" : undefined}
            onClick={() => setContactType("form")}
          >
            <a>Web問い合わせ</a>
          </li>
          <li
            className={contactType === "tel" ? "is-active" : undefined}
            onClick={() => setContactType("tel")}
          >
            <a>電話(お急ぎの方)</a>
          </li>
        </ul>
      </div>
      <div
        style={{
          maxWidth: "1000px",
          margin: "0 auto",
          display: contactType === "form" ? "" : "none",
        }}
      >
        <ContactForm fixedTypeName={contactFormType} />
      </div>

      <div
        style={{
          maxWidth: "700px",
          margin: "0 auto",
          display: contactType === "tel" ? "" : "none",
        }}
      >
        <ContactTel />
      </div>
    </div>
  )
}

export default Contacts
