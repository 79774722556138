import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"

const ContactTel = () => {
  return (
    <div className="has-text-centered">
      <a
        className="button is-dark is-fullwidth my-4 is-size-4"
        style={{ height: "4em" }}
        href="tel:011-596-7480"
      >
        <span className="icon">
          <FontAwesomeIcon icon={faPhone} />
        </span>
        <span>
          011-596-7480 <br />
          <span className="is-size-6">受付: 平日 10:00 - 19:00</span>
        </span>
        <br />
      </a>
    </div>
  )
}

export default ContactTel
