import {
  faEnvelope,
  faPhone,
  faUser,
  faFax,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

const ContactForm = ({ fixedTypeName }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitSucceeded, setIsSubmitSucceeded] = useState(false)
  const [isSubmitReturned, setIsSubmitReturned] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    formState,
  } = useForm({
    mode: "onChange",
  })

  const formTypes = [
    { name: "buy", ja: "購入" },
    { name: "sell", ja: "売却" },
    { name: "rent", ja: "借り入れ" },
    { name: "lend", ja: "貸し出し" },
    { name: "invest", ja: "投資" },
    { name: "inherit", ja: "相続" },
    { name: "remodel", ja: "リフォーム" },
  ]

  const accessTypes = [
    { name: "phone", ja: "電話" },
    { name: "mail", ja: "メール" },
    { name: "any", ja: "いずれも可" },
  ]

  const onSubmit = (data, fixedTypeName) => {
    data.type = fixedTypeName || data.type
    fetch(`/api/sendmail`, {
      method: `POST`,
      body: JSON.stringify(data),
      headers: {
        "content-type": `application/json`,
      },
    })
      .then(res => {
        if (res.status === 200) {
          setIsSubmitSucceeded(true)
        } else {
          setIsSubmitSucceeded(false)
        }
      })
      .catch(() => {
        setIsSubmitSucceeded(false)
      })
      .finally(() => {
        setIsSubmitReturned(true)
        setIsSubmitting(false)
      })
  }

  return (
    <form
      action="/api/sendmail"
      method="POST"
      onSubmit={handleSubmit(data => onSubmit(data, fixedTypeName))}
    >
      <div className="field">
        <label className="label">
          <span className="tag is-danger">必須</span>お名前
        </label>
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            placeholder="ファースト太郎"
            name="name"
            {...register("name", { required: true })}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faUser} />
          </span>
          {errors.name && <p className="help is-danger">必須入力です</p>}
        </div>
      </div>
      <div className="field">
        <label className="label">
          <span className="tag is-danger">必須</span>
          お名前ふりがな/フリガナ
        </label>
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            placeholder="ファーストたろう"
            name="name_kana"
            {...register("name_kana", {
              required: true,
              pattern: /^[ぁ-んｦ-ﾟァ-ヶー　]*$/,
            })}
          />
          <span className="icon is-small is-left">か</span>
        </div>
        {errors.name_kana && (
          <p className="help is-danger">
            ひらがなもしくはカタカナのみ入力してください
          </p>
        )}
      </div>
      <div className="field">
        <label className="label">
          <span className="tag is-danger">必須</span>
          メールアドレス
        </label>
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            placeholder="example@first.co.jp"
            name="email"
            {...register("email", {
              required: true,
              pattern:
                /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/,
            })}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
        </div>
        {errors.email && (
          <p className="help is-danger">
            メールアドレスの形式で入力してください
          </p>
        )}
      </div>
      <div className="field">
        <label className="label">
          <span className="tag is-danger">必須</span>
          電話番号（ハイフンなし）
        </label>
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            placeholder="0115967480"
            name="tel"
            {...register("tel", { pattern: /^[0-9０-９]+$/, minLength: 10 })}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faPhone} />
          </span>
        </div>
        {errors.tel && (
          <p className="help is-danger">10文字以上の数字を入力してください</p>
        )}
      </div>
      <div className="field">
        <label className="label">
          <span className="tag">任意</span>
          FAX番号（ハイフンなし）
        </label>
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            placeholder="0115967490"
            name="fax"
            {...register("fax", {
              required: false,
              pattern: /^[0-9０-９]+$/,
              minLength: 10,
            })}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faFax} />
          </span>
        </div>
        {errors.fax && (
          <p className="help is-danger">10文字以上の数字を入力してください</p>
        )}
      </div>

      <div className="field">
        <label className="label">
          <span className="tag is-danger">必須</span>
          ご希望の連絡方法
        </label>
        <div className="buttons has-addons">
          {accessTypes.map(accessType => (
            <label
              key={accessType.name}
              className={`button ${
                watch("accessType") === accessType.name ? "is-success" : ""
              }`}
              onClick={() =>
                setValue("accessType", accessType.name, {
                  shouldDirty: true,
                })
              }
            >
              {accessType.ja}
            </label>
          ))}
        </div>
        {errors.accessType && <p className="help is-danger">必須入力です</p>}{" "}
      </div>
      {!fixedTypeName && (
        <div className="field">
          {fixedTypeName}
          <label className="label">
            <span className="tag is-danger">必須</span>
            問い合わせ種別
          </label>
          <div className="buttons has-addons">
            {formTypes.map(type => (
              <label
                key={type.name}
                className={`button ${
                  watch("type") === type.name || type.name === fixedTypeName
                    ? "is-success"
                    : ""
                }`}
                onClick={() =>
                  setValue("type", type.name, {
                    shouldDirty: true,
                  })
                }
              >
                {type.ja}
              </label>
            ))}
          </div>
          {errors.type && <p className="help is-danger">必須入力です</p>}
        </div>
      )}
      <div className="field">
        <label className="label">
          <span className="tag is-danger">必須</span>
          ご要望/問い合わせ内容
        </label>
        <div className="control">
          <textarea
            className="textarea"
            placeholder="予算○○円以内、x日までに返信希望、xx駅近辺など、自由にお書きください"
            name="requirement"
            {...register("requirement", { required: true })}
          ></textarea>
        </div>
        {errors.requirement && <p className="help is-danger">必須入力です</p>}
      </div>
      <div className="field has-text-centered">
        <label className="checkbox is-large">
          <span className="tag is-danger">必須</span>
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            プライバシーポリシー
          </a>
          に同意する
          <input
            className="ml-3"
            type="checkbox"
            {...register("is-agree-pp", {
              required: true,
            })}
          />
        </label>
      </div>
      <article
        className="message is-success my-3 slidein is-animated"
        style={{ display: isSubmitSucceeded ? "inherit" : "none" }}
      >
        <div className="message-header">
          <p>お問い合わせありがとうございます</p>
        </div>
        <div className="message-body">
          ご入力された情報を送信しました。
          <br />
          お問い合わせいただいた内容につきましては、近日中にお返事させていただきます。
        </div>
      </article>
      <article
        className="message is-danger my-3 slidein is-animated"
        style={{
          display: !isSubmitSucceeded && isSubmitReturned ? "inherit" : "none",
        }}
      >
        <div className="message-header">
          <p>情報の送信に失敗しました</p>
        </div>
        <div className="message-body">
          申し訳ございません、ご入力された情報の送信に失敗しました。
          <br />
          恐れ入りますが、時間をおいて再度お問い合わせください。
        </div>
      </article>
      <button
        className={`button is-fullwidth is-info ${
          isSubmitting ? "is-loading" : ""
        }`}
        type="submit"
        disabled={
          !formState.isValid || formState.isSubmitting || isSubmitSucceeded
        }
        onClick={() => setIsSubmitting(true)}
      >
        送信
      </button>
    </form>
  )
}

export default ContactForm
